'use client'

// MUI Imports
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

// Third-party Imports
import classnames from 'classnames'
import { type ClientSafeProvider, type LiteralUnion, signIn } from 'next-auth/react'
import type { BuiltInProviderType } from 'next-auth/providers/index'

// Type Imports
import Link from '@mui/material/Link'

import type { Mode } from '@core/types'

// Hook Imports
import { useSettings } from '@core/hooks/useSettings'



const LoginV2 = ({ providers }: {
  mode: Mode,
  providers: Record<LiteralUnion<BuiltInProviderType, string>, ClientSafeProvider> | null
}) => {
  // Hooks
  const { settings } = useSettings()

  return (
    <div className="flex bs-full justify-center">
      <div
        className={classnames(
          'flex bs-full items-center justify-center flex-1 min-bs-[100dvh] relative p-6 max-md:hidden',
          {
            'border-ie': settings.skin === 'bordered'
          }
        )}
      >
        <img alt="CPKC Livery" src="/CPKC-Livery-06-12-24-2100x14011.png"
             className="absolute bottom-[4%] z-[-1] is-full max-md:hidden" />
      </div>
      <div
        className="flex flex-col justify-center items-center bs-full bg-backgroundPaper !min-is-full px-6 md:!min-is-[unset] md:px-12 md:is-[480px]">
        <div
          className="flex flex-col items-center justify-center gap-5 bs-full sm:is-auto md:is-full sm:max-is-[400px] md:max-is-[unset] mbs-11 sm:mbs-14 md:mbs-0 md:pt-[20%]">
          <div>
            <Typography className="mb-2" variant="h5">Canadian Pacific Kansas City Permit Application</Typography>
            <Typography fontSize=".9em" className="mbs-1">Note: To update or check the status of an
              existing application, sign into the <Link href="https://jllrpg.360works.com/fmi/webd/rpo_web_kcs.fmp12">legacy
                system</Link></Typography>
          </div>
          {providers ?
            Object.values(providers).map((provider) => (
              <div key={provider.name} className=" w-full">
                <Button onClick={() => signIn(provider.id)} fullWidth variant="contained" type="submit">
                  Sign In / Sign Up
                </Button>
              </div>
            ))
            :
            <Typography color="error">We were unable to load any login providers. Please contact an
              administrator.</Typography>
          }
          <Grid container spacing={2}>
            <Grid xs={12} className="mt-4">
              <Typography fontSize=".9rem" textAlign="center" fontWeight="bold">PLEASE READ THE TERMS OF USE AND
                DISCLAIMERS CAREFULLY BEFORE USING THIS WEB SITE</Typography>
            </Grid>
            <Grid item xs={6} textAlign="center">
              <Link target="_blank" href="https://www.us.jll.com/en/privacy-statement">
                Privacy Statement
              </Link>
            </Grid>
            <Grid item xs={6} textAlign="center">
              <Link target="_blank" href="https://www.us.jll.com/en/terms-of-service">
                Terms of Service
              </Link>
            </Grid>
          </Grid>
        </div>
        <Typography fontSize=".9em" textAlign="center" className="mb-1">
          For Support, Contact:<br />
          Denise Case |
          (817)513-5171 |
          Denise.Case@jll.com
        </Typography>
      </div>
    </div>
  )
}

export default LoginV2
